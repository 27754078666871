<template>
	<section class="partnership">
		<h2>Współpracujemy z:</h2>

		<div class="partnership__content">
			<div class="quaestus">
				<img src="../../../../assets/images/quaestus.png" alt="quaestus logo">

				<transition name="details">

					<div class="partnership__details">
						<p>
							Kompleksowa obsługa z obszaru księgowości oraz kadr i płac.
						</p>

<!--						<ul class="partnership__list">-->
<!--							<li class="partnership__list-item">-->
<!--								Tworzenie i wdrażanie systemów kompetencyjnych wspierających rozwój pracowników,-->
<!--							</li>-->
<!--							<li class="partnership__list-item">-->
<!--								Executive Coaching’u i wsparcie dla Zarządów w zarządzaniu ludźmi,-->
<!--							</li>-->
<!--							<li class="partnership__list-item">-->
<!--								Szkolenia z zakresu zarządzania, sprzedaży i umiejętności interpersonalnych.-->
<!--							</li>-->
<!--						</ul>-->
					</div>

				</transition>
			</div>

			<i class="i-bar"></i>

			<div class="mvp">
				<img src="../../../../assets/images/mvp.png" alt="mvp logo">

				<transition name="details">

					<div class="partnership__details">
						<p>
							Efektywne doradztwo podatkowe, świadczone przez doświadczonych praktyków.
						</p>

<!--						<ul class="partnership__list">-->
<!--							<li class="partnership__list-item">-->
<!--								Tworzenie i wdrażanie systemów kompetencyjnych wspierających rozwój pracowników,-->
<!--							</li>-->
<!--							<li class="partnership__list-item">-->
<!--								Executive Coaching’u i wsparcie dla Zarządów w zarządzaniu ludźmi,-->
<!--							</li>-->
<!--							<li class="partnership__list-item">-->
<!--								Szkolenia z zakresu zarządzania, sprzedaży i umiejętności interpersonalnych.-->
<!--							</li>-->
<!--						</ul>-->
					</div>
				</transition>
			</div>

			<i class="i-bar"></i>

			<div class="inconsulting">
				<img src="../../../../assets/images/inconsulting.png" alt="inconsulting logo">

				<transition name="details">

					<div class="partnership__details">
						<p>
							Optymalizacja wynagrodzeń, premii, procesów, kompetencji i strategii.
						</p>

<!--						<ul class="partnership__list">-->
<!--							<li class="partnership__list-item">-->
<!--								Tworzenie i wdrażanie systemów kompetencyjnych wspierających rozwój pracowników,-->
<!--							</li>-->
<!--							<li class="partnership__list-item">-->
<!--								Executive Coaching’u i wsparcie dla Zarządów w zarządzaniu ludźmi,-->
<!--							</li>-->
<!--							<li class="partnership__list-item">-->
<!--								Szkolenia z zakresu zarządzania, sprzedaży i umiejętności interpersonalnych.-->
<!--							</li>-->
<!--						</ul>-->
					</div>

				</transition>
			</div>
		</div>
	</section>
</template>

<script>
export default {
	name: 'Partnership',
	data() {
		return {
			inconsulting: false,
			mvp: false,
			quaestus: false,
		}
	},
}
</script>

<style lang="sass">

.details-enter-active
	animation: show .5s

@keyframes show
	0%
		opacity: 0

	100%
		opacity: 1

</style>

<style lang="sass" scoped>
@import 'src/assets/sass/variables'

.inconsulting, .mvp, .quaestus
	width: 50em
	display: flex
	flex-direction: column
	align-items: center

.inconsulting
	img
		width: 30%
		margin-bottom: 10px

.mvp
	img
		width: 15%
		margin-bottom: 15px

.quaestus
	img
		width: 25%

.i-bar
	position: relative
	width: 1px
	height: 150px
	background: black

.partnership
	min-height: 50vh
	display: flex
	padding: 50px 0 50px 0
	flex-direction: column
	justify-content: center
	align-items: center

	h2
		margin: 0
		font:
			size: 2.1rem
			weight: 700

	&__content
		width: 100%
		min-height: 28vh
		height: auto
		display: flex
		flex-direction: row
		align-items: center
		justify-content: space-evenly
		transition: all .5s ease

	&__details
		width: 80%
		padding-top: 30px

		p
			margin-bottom: 10px

		li
			list-style: disc

			&::marker
				font-size: 1rem

	&__details :is(p, li)
		color: $color--gray
		text-align: left
		font:
			size: 1.6rem
			weight: 400
		line-height: 27px

</style>
<style lang="sass" scoped>
@import '../../../../assets/sass/variables'

@media screen and (min-width: 768px) and (min-height: 1024px)
	.partnership
		min-height: 15vh

		&__content
			min-height: 25vh

		&__details
			display: block !important

@media screen and (min-width: 320px) and (max-width: 980px)

	.i-bar
		transform: rotate(90deg)

	.partnership
		width: 100vw
		padding: 0

		h2
			font-size: 1.6rem
			margin: 30px 0

		&__content
			flex-direction: column
			align-items: center
			justify-content: center
			margin-bottom: 50px

		&__details
			width: 80%
			padding: 0 30px
			margin-top: 30px
			text-align: left
			display: none
			flex-direction: column

			p
				padding: 0 30px
				font-size: .9rem

		ul
			margin-top: 10px
			padding: 0 40px

			li
				text-align: left
				font-size: .7rem
				padding: 5px 0 0 5px

				&::marker
					font-size: .5rem
					padding-bottom: 10px

</style>
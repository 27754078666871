<template>
	<nav class="navbar">
		<div class="logo">
			<router-link to="/" @click.capture="hideNavbar">
				<img src="../../../assets/images/logo.svg" alt="logo">
			</router-link>
		</div>

		<div class="mobile__nav-icons">
			<i
					class="fas fa-bars fw"
					:class="{hiddenIcon: isVisible}"
					@click="showNavbar"
			>
			</i>
			<i
					class="fas fa-times fw"
					:class="{visibleIcon: isVisible}"
					@click="showNavbar"
			>
			</i>
		</div>

		<ul class="navbar__list">
			<li
					class="navbar__item"
					v-for="link in navItems"
					:key="link.id"
					@click.capture="handleLink"
			>
				<router-link
						class="navbar__link"
						:text="link.name"
						:after-text="link.name"
						:to="link.path"
						@click.capture="hideNavbar"
				>
					{{ link.name }}
				</router-link>
			</li>
		</ul>
	</nav>
</template>

<script>

import {prepareRouterLinks} from '@/mixins/globalMixins'

export default {
	name: 'Navbar',
	mixins: [prepareRouterLinks],
	data() {
		return {
			isVisible: false
		}
	},
	computed: {
		navItems() {
			return this.globalRouterLinks.slice(1, 5)
		},
	},
	methods: {
		hideNavbar() {
			const navbar = document.querySelector('.navbar')
			if(navbar.classList.contains('visible')) {
				navbar.classList.remove('visible')
				this.isVisible = !this.isVisible
			}
		},
		showNavbar() {
			const navbar = document.querySelector('.navbar')
			this.isVisible = !this.isVisible

			if (this.isVisible === true)
				navbar.classList.add('visible')
			else
				navbar.classList.remove('visible')
		},

		emitEvent() {
			this.$emit('clicked')
		},

		handleLink(link) {
			if (link.target.firstChild.nodeValue === 'Blog') {
				link.preventDefault()
			}
		},
	}
}
</script>

<style lang="sass" scoped>
@import '../../../assets/sass/variables'

.router-link-active
	font-weight: 700

	&::before
		position: absolute
		width: 100%
		height: 100%
		content: url("../../../assets/images/fill.png")
		bottom: -120%
		left: 50%

.navbar
	position: relative
	width: 100%
	height: 12vh
	display: flex
	flex-direction: row
	justify-content: space-between
	padding-top: 20px

	&__list
		width: 45%
		height: 100%
		margin-right: 60px
		display: flex
		flex-direction: row
		justify-content: space-evenly
		align-items: center

	&__item
		font-weight: 700

		&:nth-child(3)
			a
				color: gray

				&::before
					display: none

	&__link
		position: relative
		text-transform: uppercase
		letter-spacing: 0

.logo
	position: relative
	left: 7%
	max-width: 230px
	min-width: 230px
	display: flex
	justify-content: center
	align-items: center

	a
		width: 70%

		&::before
			display: none

	img
		width: 95%
</style>

<style lang="sass" scoped>
@import '../../../assets/sass/variables'

.hiddenIcon
	display: none

.visibleIcon
	display: inline-block !important

.visible
	position: fixed !important
	min-height: 35.8em !important

	.logo
		top: 4% !important

	.navbar__list
		position: relative
		display: flex
		padding: 70px 0 50px 0
		height: 100%

.mobile__nav-icons
	display: none

@media screen and (min-width: 320px) and (max-width: 980px)

	.mobile__nav-icons
		position: absolute
		height: 25px
		display: flex
		left: 85%
		z-index: 501

		i
			position: relative
			top: 90%
			font-size: 2.5rem
			cursor: pointer

		.fa-times
			display: none

	.navbar
		width: 100vw
		height: 8.1em
		z-index: 5000
		padding-top: 0

		.logo
			position: absolute
			top: 15%
			left: 0
			z-index: 300

			a
				width: 40%

		&__list
			width: 100%
			flex-direction: column
			background: white
			display: none
			margin-right: 0

		&__item
			font-weight: 700
			font-size: 1.4rem
</style>
<template>
	<div class="employees">
		<div
				class="person"
				v-for="person in persons"
				:key="person.fullName"
		>
			<div class="person__image">
				<img :src="person.image" :alt="person.fullName">
			</div>

			<div class="person__info">
				<h4 class="person__law">{{ person.law }}</h4>
				<h3 class="person__name">{{ person.fullName }}</h3>
				<p class="person__text">{{ person.text1 }}</p>
				<p class="person__text">{{ person.text2 }}</p>
				<p
						class="person__text"
						v-if="person.fullName === 'Izabela Zawacka'
				    ||
				        person.fullName === 'Łukasz Rdzeń'"
				>
					{{ person.text3 }}
				</p>
				<p
						class="person__text"
						v-if="person.fullName === 'Izabela Zawacka'"
				>
					{{ person.text4 }}
				</p>

				<Button
						v-if="person.fullName === 'Izabela Zawacka'"
						buttonStyle="secondary"
						buttonSize="small"
						:buttonText="expandText"
						class="expand"
						@click="expand"
				></Button>
			</div>

		</div>
	</div>
</template>

<script>
import persons from '../PersonOverview'

export default {
	name: 'Person',
	functional: true,
	data() {
		return {
			persons: persons,
			expandText: 'Rozwiń',
			expanded: false,
		}
	},
	methods: {
		expand() {
			const person = document.querySelector('.person')

			if (!person.classList.contains('expanded')) {

				setTimeout(() => {
					this.expandText = 'Ukryj'
					person.classList.add('expanded')
				}, 0)
			} else {
				person.classList.remove('expanded')
				this.expandText = 'Rozwiń'
			}
		},
	}
}
</script>

<style lang="sass">
@import 'src/assets/sass/variables'

.expanded
	max-height: 100% !important

.expand
	position: absolute
	bottom: 0
	left: 0
	width: 100%
	padding: 10px
	margin: 15px 0 0 0
	letter-spacing: 2px
	font-weight: 500 !important

	&:hover::before
		width: 0 !important

	&:hover
		color: $color--dark !important

.employees
	width: 100%
	display: flex
	flex-direction: column

.person
	display: flex
	flex-direction: row
	justify-content: space-between
	text-align: left
	box-shadow: 0 0 15px 0 rgba(0, 0, 0, .2)
	margin: 50px 0
	height: auto

	&:nth-child(1)
		max-height: 46em

		.person__image
			max-height: 46em

		.person__info
			position: relative
			overflow: hidden

	&:nth-child(1).expanded
		.person__image
			img
				top: 0


	&__image
		position: relative
		height: auto
		min-height: 44.4em
		max-height: 44.4em
		min-width: 39em
		margin-left: 50px
		display: flex

		//img
		//	max-width: 100%
		//	height: auto

		img
			position: absolute
			bottom: 0
			left: 50%
			transform: translateX(-50%)

	&__info
		padding: 50px

	&__info :is(h3, h4, p)
		font-family: $font--primary

	&__law
		font-size: 1.3rem
		font-weight: 400
		text-transform: uppercase
		margin-bottom: 15px

	&__name
		font-size: 2.8rem
		font-weight: 700

	&__text
		font-size: 1.5em
		font-weight: 300
		line-height: 27px
		margin-top: 30px
</style>

<style lang="sass">

@media screen and (min-width: 320px) and (max-width: 980px)

	.expanded
		max-height: 100% !important

		.person__info
			overflow: unset
			padding-bottom: 50px
			max-height: unset !important
			height: 100% !important

			button
				bottom: 0

	.expand
		display: block

	.person
		flex-direction: column
		margin: 15px 0
		justify-content: center

		&:nth-child(1)
			max-height: unset

			.person__info
				min-height: 50vh
				max-height: 50vh

		&:nth-child(2)

			.person__image

				img
					width: 80%

		&__image
			min-height: 100%
			padding-top: 30px
			min-width: unset
			margin-left: 0
			text-align: center

			img
				position: unset
				transform: unset

		&__law
			text-align: center
			margin-bottom: 0

		&__name
			text-align: center
			font-size: 1.9rem

		&__text
			font-size: 1.3rem
			line-height: 19px

	.employees
		margin-top: 36px
		padding: 0 10px

@media screen and (min-width: 768px) and (min-height: 1024px)
	.expanded
		min-height: 125em !important
		max-height: 125em !important

	.person
		width: 95% !important
		margin: 50px auto !important

		&__image
			min-height: unset !important
			max-height: unset !important
</style>

<script>
const clients = [
	{
		name: 'client3',
		id: 3,
		src: require('../../../../assets/images/clients/billtech.png')
	},
	{
		name: 'client5',
		id: 5,
		src: require('../../../../assets/images/clients/gpw.png')
	},
	{
		name: 'client2',
		id: 2,
		src: require('../../../../assets/images/clients/4mats.png')
	},
	{
		name: 'client14',
		id: 14,
		src: require('../../../../assets/images/clients/wedel.png')
	},
	{
		name: 'client1',
		id: 1,
		src: require('../../../../assets/images/clients/2win.png')
	},
	{
		name: 'client4',
		id: 4,
		src: require('../../../../assets/images/clients/enea.png')
	},
	{
		name: 'client12',
		id: 12,
		src: require('../../../../assets/images/clients/thermes.png')
	},
	{
		name: 'client10',
		id: 10,
		src: require('../../../../assets/images/clients/polimex.png')
	},
	{
		name: 'client13',
		id: 13,
		src: require('../../../../assets/images/clients/nanny.png')
	},
	{
		name: 'client9',
		id: 9,
		src: require('../../../../assets/images/clients/orpea.png')
	},
	{
		name: 'client15',
		id: 15,
		src: require('../../../../assets/images/clients/impel.png')
	},
	{
		name: 'client8',
		id: 8,
		src: require('../../../../assets/images/clients/mobime.png')
	},
	{
		name: 'client6',
		id: 6,
		src: require('../../../../assets/images/clients/inter.png')
	},
	{
		name: 'client7',
		id: 7,
		src: require('../../../../assets/images/clients/merlin.png')
	},
	{
		name: 'client11',
		id: 11,
		src: require('../../../../assets/images/clients/proma.png')
	},
	// {
	// 	name: 'client16',
	// 	id: 16,
	// 	src: require('../../../../assets/images/clients/quaestus.png')
	// },
]

export default clients
</script>
<template>
	<header class="header">
		<div class="header__content">
			<div class="header__background">
				<div class="header__background--gradient"></div>

				<div class="header__headers">
					<h2>Jesteśmy kancelarią, która działa w zakresie: </h2>
					<h3>prawa gospodarczego i prawa pracy</h3>
				</div>
			</div>

			<div class="header__belt">
				<h2>Obsługujemy:</h2>

				<ul class="header__list">
					<div class="items-box">
						<li class="header__list-item">e-comerce</li>
						<li class="header__list-item">IT</li>
					</div>
					<div class="items-box">
						<li class="header__list-item">polskie spółki</li>
						<li class="header__list-item">korporacje</li>
					</div>
					<div class="items-box">
						<li class="header__list-item">deweloperów</li>
						<li class="header__list-item">startupy</li>
					</div>
					<div class="items-box">
						<li class="header__list-item">inwestorów</li>
					</div>
				</ul>

				<router-link to="/oferta">
					<Button
							buttonStyle="default"
							buttonSize="large"
							buttonText="Zobacz Ofertę"
							:icon="true"
					>
					</Button>
				</router-link>
			</div>
		</div>
	</header>
</template>

<script>
export default {
	name: 'Header',
	functional: true,
}
</script>

<style lang="sass" scoped>
@import '../../../../assets/sass/variables'

.header
	width: 100%
	height: auto

	&__content
		width: 100%

	&__background
		position: relative
		width: 100%
		height: 44.5em
		background-image: url("../../../../assets/images/mainHeader.png")
		z-index: 1

	&__background--gradient
		position: absolute
		top: 0
		left: 0
		width: 100%
		height: 100%
		background-image: linear-gradient(90deg, rgba(25, 25, 25, 0) 0%, rgba(255, 255, 255, .95) 65%, rgba(255, 255, 255, 1) 100%)

	&__headers
		position: absolute
		bottom: 60px
		right: 150px
		text-align: right

	&__headers :is(h2, h3)
		font-family: $font--primary

	&__headers :is(h2)
		font-weight: 300
		font-size: 3.2rem

	&__headers :is(h3)
		margin-top: 10px
		font-size: 2.9rem
		font-weight: 500

	&__belt
		width: 100%
		padding: 0 120px 0 50px
		height: 13.2em
		background: $color--accent
		color: $color--light
		display: flex
		flex-direction: row
		justify-content: space-evenly
		align-items: center

		h2
			font-size: 2.1rem
			font-weight: 700

	&__list
		width: 50%
		display: flex
		flex-direction: row
		flex-wrap: wrap
		justify-content: space-between
		text-align: left

	&__list-item
		text-transform: uppercase
		line-height: 35px
		font-size: 1.3rem
		font-weight: 700
		cursor: default
</style>

<style lang="sass" scoped>
@import '../../../../assets/sass/variables'

@media screen and (min-width: 768px) and (min-height: 1024px)
	.header

		&__belt
			padding: 0

@media screen and (min-width: 320px) and (max-width: 980px)

	.header
		height: 100%

		&__background
			position: relative
			width: 100vw
			height: 23.2em
			background: url("../../../../assets/images/mobile/header.png") no-repeat top center
			background-size: 101vw 23.2em

			&--gradient
				display: none

		&__headers
			position: relative
			width: 100%
			padding: 25px 70px
			background-image: linear-gradient(180deg, rgba(255, 255, 255, 0.86) 0%, #ffffff 100%)
			top: calc(100% - 109px)
			right: 0
			text-align: center

			h2
				font-size: 1.6rem
				padding: 0 25px
				line-height: 21px

			h3
				margin-top: 0
				font-size: 1.4rem

		&__belt
			width: 100vw
			height: unset
			padding: 30px 0
			flex-direction: column
			justify-content: center
			align-items: center

			h2
				font-size: 1.5rem
				padding-bottom: 10px

			a
				margin: 0 auto

		&__list
			width: 70%
			margin-bottom: 30px

		&__list-item
			min-width: 103px
			text-align: left
			font-size: 1.2rem
</style>

<template>
	<Offer>
		<template #banner>
			<img slot="banner" v-if='mobile === false' src="../../../../../assets/images/banners/corp.png"
			     alt="corp banner">
			<img slot="banner" v-else src="../../../../../assets/images/mobile/corp.png"
			     alt="corp mob banner">
		</template>

		<template #button>
			<i v-if="mobile === true" class="fas fa-arrow-left"></i>
			<router-link slot="button" to="/oferta">Wróć do spisu usług</router-link>
		</template>

		<template #small-title>
			<h4>prawo gospodarcze</h4>
		</template>

		<template #title>
			<h2>Obsługa korporacyjna</h2>
		</template>

		<template #text>
			<p>Zapewniamy szeroko rozumianą kompleksową obsługę prawną spółek prawa handlowego oraz przedsiębiorców
				prowadzących jednoosobową działalność gospodarczą. Poczynając od bieżącej obsługi wraz z prowadzeniem
				dokumentacji korporacyjnej, poprzez tworzenie, weryfikację i opiniowanie projektów uchwał organów, jak
				również czynny udział w obradach rad nadzorczych, walnych zgromadzeń wspólników i akcjonariuszy, co
				pozwala na zachowanie pełnego profesjonalizmu i skuteczności podejmowanych działań. Obsługując organy
				spółek, przygotowujemy również umowy o współpracy oraz porozumienia wspólników, a także zapewniamy
				doradztwo
				w zakresie prawa pracy.</p>
		</template>

		<template #range>
			<h3>ZAKRES:</h3>
		</template>

		<template #list>
			<ul class="offer__list">
				<li class="offer__list-item">Bieżąca obsługa korporacyjna spółek</li>
				<li class="offer__list-item">Postępowanie upadłościowe i restrukturyzacyjne spółki</li>
				<li class="offer__list-item">Doradztwo w zakresie zgodności z obowiązującymi przepisami prawnymi,
					obejmujące sprawozdania zarządu, roczne sprawozdania z finansowe, uchwały wspólników i inne
					dokumenty korporacyjne
				</li>
				<li class="offer__list-item">Zmiany składu zarządu, kapitału zakładowego, firmy spółki etc.</li>
				<li class="offer__list-item">Składanie dokumentów do rejestrów sądowych</li>
				<li class="offer__list-item">Sprzedaż udziałów / akcji</li>
				<li class="offer__list-item">Tworzenie oddziałów i przedstawicielstw przedsiębiorców zagranicznych</li>
				<li class="offer__list-item">Opracowywanie założeń przekształceń kapitałowych, tworzenia holdingów,
					konsorcjów i grup kapitałowych
				</li>
				<li class="offer__list-item">Obligacje korporacyjne
				</li>
				<li class="offer__list-item">Programy opcyjne</li>
			</ul>
		</template>
	</Offer>
</template>

<script>
import Offer from '../Offer'
import {handleWith} from "@/mixins/globalMixins";

export default {
	name: 'Corp',
	mixins: [handleWith],
	components: {
		Offer
	},
}
</script>

<template>
	<section class="clients">
		<h2>Zaufali nam:</h2>

		<div class="clients__content">

			<div
				v-for="(photo) in photos"
				class="photo"
			>
				<img
					class="client__photo"
					:src="photo.src"
		            :alt="photo.alt"
				/>
			</div>
		</div>
	</section>
</template>

<script>
import photos from '../Photos/Photos'

export default {
	name: 'Clients',
	components: {
		photos,
	},
	functional: true,
	data() {
		return {
			photos: photos,
		}
	}
}
</script>

<style lang="sass" scoped>
@import 'src/assets/sass/variables'

.clients
	width: 100%
	min-height: 52.3em
	background: $color--accent
	display: flex
	flex-direction: column
	justify-content: center
	align-items: center

	h2
		position: relative
		top: -40px
		display: inline-block
		font:
			size: 2.1rem
			weight: 700
			family: $font--default
		color: $color--light
		margin-bottom: 10px

	&__content
		width: 100%
		display: flex
		flex-wrap: wrap
		align-items: center
		justify-content: flex-start
		padding: 0 215px

	.photo
		text-align: center
		width: 20em
		height: auto
		margin: 25px 0

		img
			width: 52%
</style>

<style lang="sass" scoped>
@import '../../../../assets/sass/variables'

@media screen and (min-width: 768px) and (min-height: 1024px)
	.clients

		h2
			top: 35px

		&__content
			padding: 50px 100px

@media screen and (min-width: 320px) and (max-width: 980px)
	.clients
		width: 100vw
		padding: 20px 0

		h2
			top: 0

		&__content
			padding: 0
			justify-content: unset

			.photo
				margin: 25px auto
				width: 15em

				&:nth-child(15)
					text-align: center
					margin: 25px 0 25px 25px
</style>
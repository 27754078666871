<template>
	<section class="contact">
		<div class="contact__header">
			<div class="contact__banner">
				<img v-if="mobile === false" src="../../../assets/images/contact.png" alt="contact banner">
				<img v-else src="../../../assets/images/mobile/contact.png" alt="contact banner">
			</div>

			<div class="contact__logo">
				<img src="../../../assets/images/logo.svg" alt="logo">
			</div>

			<div class="contact__header-content">

				<div class="contact__address">
					<p>ul. Sarmacka 1A lok 145</p>
					<p>02-972 Warszawa</p>
				</div>

				<div class="contact__types">
					<p>kancelaria@zawacka-rdzen.pl</p>
					<p>tel. 570 769 115</p>
				</div>
			</div>
		</div>

		<div class="contact__content">
			<Form></Form>
		</div>
	</section>
</template>

<script>
import Form from './components/Form'
import {handleWith, footerVisibility} from "@/mixins/globalMixins";

export default {
	name: 'Contact',
	components: {
		Form
	},
	mixins: [handleWith, footerVisibility],
	data() {
		return {}
	}
}
</script>

<style lang="sass" scoped>
@import 'src/assets/sass/variables'

input, textarea
	outline: none
	border: 0

.contact
	padding-top: 50px

	&__header
		position: relative
		min-height: 60vh
		margin-bottom: 100px

		&::after
			position: absolute
			content: ''
			width: 35%
			height: 1px
			bottom: 0
			left: 50%
			transform: translateX(-50%)
			opacity: .6
			background: gray

	&__logo
		margin-top: 50px
		text-align: center

		img
			width: 16%

	&__header-content
		padding-top: 25px
		min-height: 25vh
		display: flex
		flex-direction: column
		align-items: center
		justify-content: space-evenly

		p
			font:
				size: 1.6rem
				weight: 300

	&__types
		width: 50%
		margin: auto
		display: flex
		flex-direction: row
		justify-content: space-between
		align-items: center

		p
			font:
				size: 2.4rem
				weight: 700
</style>

<style lang="sass" scoped>
@media screen and (min-width: 320px) and (max-width: 980px)

	.contact
		padding-top: 0

		&__banner
			width: 100%

			img
				width: 100%

		&__header
			min-height: 55vh
			margin-bottom: 30px

			&::after
				bottom: 5%

			&-content
				padding-top: 0
				height: 15vh
				min-height: unset
				justify-content: space-between

				p
					font-size: 1.5rem

				.contact__address
					text-align: center

				.contact__types
					flex-direction: column
					margin: 0

					p
						font-size: 1.3rem

						&:nth-child(1)
							margin-bottom: 10px

		&__logo
			margin-top: 25px

			img
				width: 28%

</style>
<template>
	<form method="post" class="form">

		<span class="dsadsadever" style="display: none">
			<label for="accaa">Jeżeli jesteś człowiekiem, nie wypełniaj tego pola</label>
			<input type="text" id="accaa" name="hany" v-model="formData.honey">
		</span>


		<div class="form__content">
			<div class="form__inputs">
				<h2>Formularz kontaktowy</h2>

				<div class="form__element">
					<label for="messagetopic" class="form__label">Temat wiadomości</label>
					<input
							type="text"
							class="form__input"
							v-model="formData.messageTopic"
							id="messagetopic"
							@keyup="charCounter"
							@keydown.space.enter="preventLeading"
					>
					<span class="error" v-if="errorList.title" style="color: red;">Podaj temat wiadomości</span>
				</div>

				<div class="form__element">
					<label for="fullname" class="form__label">Imię i nazwisko</label>
					<input
							type="text"
							class="form__input"
							v-model="formData.fullName"
							id="fullname"
							@keyup="charCounter"
							@keydown.space.enter="preventLeading"
					>
					<span class="error" v-if="errorList.name" style="color: red;">Podaj swoje imię i nazwisko</span>
				</div>

				<div class="form__element">
					<label for="email" class="form__label">Email</label>
					<input
							type="email"
							class="form__input"
							v-model="formData.email"
							id="email"
							@keydown.space.enter="preventLeading"
					>
					<span class="error" v-if="errorList.email.empty" style="color: red;">Podaj email</span>
					<span class="error" v-if="errorList.email.format" style="color: red;">Zły format maila</span>
				</div>

				<div class="form__element">
					<label for="phone" class="form__label">Numer telefonu</label>
					<input
							type="tel"
							class="form__input"
							v-model="formData.phone"
							id="phone"
							placeholder="Format: 123456789"
							@keyup="charCounter"
							@keydown.space.enter="preventLeading"
					>
					<span class="error" v-if="errorList.phone.empty" style="color: red;">Podaj numer telefonu</span>
					<span class="error" v-else-if="errorList.phone.format" style="color: red;">Zły format numeru</span>
				</div>
			</div>

			<div class="form__element">
				<label for="textarea" class="form__label">Treść wiadomości</label>
				<span class="error" v-if="errorList.message" style="color: red;">Wiadomość nie może być pusta</span>
				<textarea
						name="textarea"
						id="textarea"
						v-model="formData.message"
						class="form__textarea"
						@keyup="charCounter"
						@keydown.space.enter="preventLeading"
				></textarea>
			</div>
		</div>

		<div class="form__checkboxs">
			<div class="form__checkbox-box">
				<input
						id="checkbox-1"
						type='checkbox'
						@click="changeCheckBoxStatus"
				/>
				<label for="checkbox-1"></label>
				<p>Administratorem Twoich danych osobowych są Izabela Zawacka Kancelaria Radcy Prawnego oraz Łukasz
					Rdzeń Kancelaria Radcy Prawnego, do których kierujesz zapytanie, ul. Sarmacka 1a lok. 145, Warszawa
					(02-972). Dane osobowe przetwarzane będą w celu udzielenia odpowiedzi na zapytanie ofertowe. Pełna
					informacja o przetwarzaniu Twoich danych osobowych znajduje się w naszej
					<a href="#" @click="iframe">Polityce prywatności</a>
				</p>

			</div>
			<span class="error" v-if="checkBox1Error" style="color: red;">Zaznacz checkbox</span>
		</div>
		<div class="popup" v-if="sendStatus === true">
			Wiadomość została wysłana pomyślnie.
		</div>

		<Button
				class="form__submit"
				buttonStyle="primary"
				buttonSize="large"
				buttonText="Wyślij"
				@click.prevent="validFormBeforeSend"
		>
		</Button>
	</form>
</template>

<script>
import axios from 'axios'
const querystring = require("querystring")

export default {
	name: 'Form',
	data() {
		return {
			charLimit: {
				textarea: 7200,
				topic: 40,
				fullname: 30,
				phone: 9,
			},
			formData: {
				messageTopic: '',
				fullName: '',
				email: '',
				phone: '',
				message: '',
				honey: '',
			},

			sendStatus: false,

			errorList: {
				title: false,
				name: false,
				email: {
					empty: false,
					format: false,
				},
				phone: {
					empty: false,
					format: false,
				},
				message: false,
				amount: [],
			},

			checkBox1Status: false,
			checkBox1Error: false,
		}
	},
	methods: {
		iframe() {
			const win = window.open();
			win.document.write('<iframe width="100%" height="100%" src="https://docs.google.com/viewer?srcid=1K5Kka0WuP_tzTtnFgjPPDrCt4ROOs52Q36Z4EZ_Bxag&pid=explorer&efh=false&a=v&chrome=false&embedded=true" frameborder="0" allowfullscreen></iframe>')

		},

		charCounter() {
			const textarea = document.querySelector('textarea')
			const topic = document.querySelector('#messagetopic')
			const fullname = document.querySelector('#fullname')
			const phone = document.querySelector('#phone')

			let textareaChars = textarea.value.length
			let topicChars = topic.value.length
			let fullnameChars = fullname.value.length
			let phoneChars = phone.value.length

			if (textareaChars >= this.charLimit.textarea) {
				textarea.value = textarea.value.substr(0, this.charLimit.textarea)
			}

			if (topicChars >= this.charLimit.topic) {
				topic.value = topic.value.substr(0, this.charLimit.topic)
			}

			if (fullnameChars >= this.charLimit.fullname) {
				fullname.value = fullname.value.substr(0, this.charLimit.fullname)
			}

			if (phoneChars >= this.charLimit.phone) {
				phone.value = phone.value.substr(0, this.charLimit.phone)
			}
		},

		validData() {
			this.resetValidData()
			return new Promise((resolve, reject) => {
				if (!this.formData.messageTopic) {
					this.errorList.title = true
					this.errorList.amount.push('1')
				}

				if (!this.formData.fullName) {
					this.errorList.name = true
					this.errorList.amount.push('2')
				}

				if (!this.formData.email) {
					this.errorList.email.empty = true
					this.errorList.amount.push('3')
				} else if (!this.validEmail(this.formData.email)) {
					this.errorList.amount.push('3')
					this.errorList.email.format = true
				}

				if (!this.formData.phone) {
					this.errorList.phone.empty = true
					this.errorList.amount.push('4')
				} else if (!this.validPhone(this.formData.phone)) {
					this.errorList.amount.push('4')
					this.errorList.phone.format = true
				}

				if (!this.formData.message) {
					this.errorList.message = true
					this.errorList.amount.push('5')
				}


				if (!this.errorList.amount.length)
					resolve(true)
			})
				.catch(
					(error) => {
						console.log(error)
					}
				)
		},

		validEmail(email) {
			const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
			return regex.test(email)
		},

		validPhone(phone) {
			const phoneReg = /(?:1[2-8]|2[2-69]|3[2-49]|4[1-68]|5[0-9]|6[0-35-9]|[7-8][1-9]|9[145])\d{7}/
			return phoneReg.test(phone)
		},

		preventLeading(e) {
			if (!e.target.value)
				e.preventDefault();
			else if (e.target.value[0] === ' ' || e.target.value[0] === '\n')
				e.target.value = e.target.value.replace(/^\s*/, "")
		},

		changeCheckBoxStatus(input) {
			if (input.target.id === 'checkbox-1')
				this.checkBox1Status = !this.checkBox1Status
		},

		validCheckBox() {
			this.checkBox1Error = false

			return new Promise(resolve => {
				if (!this.checkBox1Status)
					this.checkBox1Error = true

				if (this.checkBox1Error === false) {
					resolve(true)
				}
			})

		},

		async validFormBeforeSend() {
			this.validData().then(() => {
				this.validCheckBox().then(() => {
					this.sendEmail()
				}).catch(
					(error) => {
						console.log(error)
					}
				)
			}).catch(
				(error) => {
					console.log(error)
				}
			)
		},

		resetValidData() {
			this.errorList.amount.length = 0
			this.errorList.title = false
			this.errorList.name = false
			this.errorList.email.empty = false
			this.errorList.email.format = false
			this.errorList.phone.empty = false
			this.errorList.phone.format = false
			this.errorList.message = false
			this.checkBox1Error = false
		},

		resetCheckBox() {
			document.querySelector('#checkbox-1').checked = false

			this.checkBox1Status = !this.checkBox1Status
		},

		resetDataValue() {
			this.formData = {
				messageTopic: '',
				fullName: '',
				email: '',
				phone: '',
				message: '',
			}
		},

		sendEmail() {
			const {fullName, messageTopic, email, phone, message, honey} = this.formData;
			const payload = {fullName, messageTopic, email, phone, message, honey};
			const formData = new FormData()

			for (let key in payload) {
				formData.append(key, payload[key])
			}

			axios.post('./api/file.php', formData, {
				headers: {'Content-Type': 'multipart/form-data'},
			})
				.then(() => {
					this.sendStatus = true
					this.charLimit = 7200
					this.resetDataValue()
					this.resetValidData()
					this.resetCheckBox()
					document.querySelector('.form__submit').disabled = true

					setTimeout(() => {
						document.querySelector('.form__submit').disabled = false
						this.sendStatus = false
					}, 5000)
				})
		}
	}
}
</script>

<style lang="sass" scoped>
@import 'src/assets/sass/variables'

.popup
	position: relative
	width: 18vw
	min-height: 58px
	line-height: 58px
	margin: 0 auto 30px auto
	font:
		size: 1.3rem
	text-transform: uppercase
	border: 1px solid gray
	padding: 0 25px

.form
	width: auto
	margin: auto
	display: flex
	flex-direction: column

	&__content
		display: flex
		flex-direction: row
		justify-content: center
		margin-bottom: 50px

	&__inputs
		width: 25%
		display: flex
		flex-direction: column
		margin-right: 150px

		h2
			font:
				size: 2.8rem
				family: $font--primary
				weight: 700
			margin-bottom: 20px

	&__element
		display: flex
		flex-direction: column
		margin: 15px 0

		&:nth-child(5)
			width: 60%

	&__label
		font:
			size: 1.3rem
			weight: 300
			family: $font--primary

	&__input
		outline: 0
		border: 0
		border-bottom: 1px solid gray
		padding: 0 0 10px 0
		margin-top: 10px

	&__textarea
		margin-top: 10px
		padding: 10px
		width: 42em
		height: 28em
		resize: none
		outline: none
		border: 1px solid gray

	&__checkboxs
		width: 50%
		margin: 0 auto 50px auto
		display: flex
		flex-direction: column
		align-items: center

	&__checkbox-box
		width: 70%
		display: flex
		flex-direction: row
		justify-content: space-between
		align-items: center
		margin: 25px auto

		p
			font:
				size: .9rem
				weight: 300
				family: $font--primary

			a
				font-size: .9rem
				font-weight: 500
				text-decoration: underline

		label
			position: relative
			cursor: pointer
			margin-right: 25px

			&::before
				content: ''
				-webkit-appearance: none
				background-color: transparent
				border: 1px solid gray
				border-radius: 4px
				padding: 10px
				display: inline-block
				position: relative
				vertical-align: middle
				cursor: pointer
				margin-right: 5px

		input
			padding: 0
			height: initial
			width: initial
			margin-bottom: 0
			display: none
			cursor: pointer


		input:checked + label:after
			content: ''
			display: block
			position: absolute
			top: 2px
			left: 9px
			width: 6px
			height: 14px
			border: solid black
			border-width: 0 2px 2px 0
			transform: rotate(45deg)

	&__submit
		margin: 0 600px
</style>

<style lang="sass" scoped>

@media screen and (min-width: 768px) and (min-height: 1024px)
	.popup
		width: 30em
		text-align: center

	.form

		&__submit
			margin: 0 400px

@media screen and (min-width: 320px) and (max-width: 980px)

	.form
		margin-bottom: 70px

		&__content
			padding: 0 40px
			flex-direction: column

		&__inputs
			width: 100%
			margin-right: 0

			h2
				font-size: 2.1rem
				text-align: center

		&__label
			font-size: 1.2rem

		&__textarea
			width: 100%

		&__checkboxs
			width: 100%
			margin: 0
			padding: 0 40px

		&__checkbox-box
			width: 100%
			align-items: flex-start

		&__submit
			margin: 0 120px

		.popup
			width: 80%
			text-align: center
			margin: 0 auto 20px auto
			min-height: unset
			padding: 0
			line-height: 40px
			font-size: 1.2rem

</style>
<template>
	<section class="offer">
		<div class="offer__main" @show="changeView">
			<div class="offer__left-side"></div>
			<div class="offer__content">
				<OfferNav></OfferNav>
			</div>
		</div>
	</section>
</template>

<script>
import OfferNav from "@/components/Views/Offer/components/OfferNav";
import {prepareRouterLinks, footerVisibility} from '@/mixins/globalMixins'

export default {
	name: 'OfferApp',
	mixins: [prepareRouterLinks, footerVisibility],
	components: {
		OfferNav,
	},
	data() {
		return {
			showOfferView: false,
		}
	},
	methods: {
		changeView() {
			this.showOfferView = !this.showOfferView
		},
	}
}
</script>

<style lang="sass" scoped>
@import 'src/assets/sass/variables'

.offer
	width: 100%
	padding-top: 90px

	&__main
		display: flex
		flex-direction: row

	&__left-side
		min-width: 32.6em
		max-width: 32.6em
		height: 90.2em
		background-image: url("../../../assets/images/left.png")

		img
			width: 100%

	&__content
		padding: 17px 0 30px 0
		width: 100%
		display: flex
		flex-direction: column
		align-items: center
		justify-content: space-between
</style>

<style lang="sass" scoped>
.view-enter-active
	animation: opacity-in .3s
	transition-delay: .4s
	position: absolute

@keyframes opacity-in
	0%
		opacity: 0

	100%
		opacity: 1

.view-leave-active
	animation: opacity-out .3s
	position: absolute

@keyframes opacity-out
	0%
		opacity: 1
	100%
		opacity: 0

</style>

<style lang="sass" scoped>
@media screen and (min-width: 320px) and (max-width: 980px)

	.offer
		padding: 0

		&__main
			flex-direction: column

		&__left-side
			width: 100vw
			min-width: 100vw
			max-width: 100vw
			height: 24.8em
			background-image: url("../../../assets/images/mobile/offer.png")
			background-position: bottom center
			background-repeat: no-repeat
			background-size: cover

</style>
import {createRouter, createWebHistory} from 'vue-router'
import Corp from '../components/Views/Offer/components/EconomicPages/Corporation.vue'
import Mergers from '../components/Views/Offer/components/EconomicPages/Mergers.vue'

const routes = [
	{
		path: '/',
		name: 'name',
		component: () => import('../components/Views/Home/Home.vue'),
	},
	{
		path: '/oferta',
		name: 'Oferta',
		component: () => import('../components/Views/Offer/OfferApp.vue'),
	},
	{
		path: '/onas',
		name: 'O nas',
		component: () => import('../components/Views/About/About.vue')
	},
	{
		path: '/',
		name: 'Blog',
		component: () => import('../components/Views/Home/Home.vue')
	},
	{
		path: '/kontakt',
		name: 'Kontakt',
		component: () => import('../components/Views/Contact/Contact.vue')
	},


	//economics
	{
		path: '/korporacje',
		name: 'Obsługa korporacyjna',
		component: () => import('../components/Views/Offer/components/EconomicPages/Corporation.vue')
	},
	{
		path: '/fuzje',
		name: 'Fuzje i przejęcia',
		component: () => import('../components/Views/Offer/components/EconomicPages/Mergers.vue')
	},
	{
		path: '/sukcesje',
		name: 'Sukcesja w firmach',
		component: () => import('../components/Views/Offer/components/EconomicPages/SuccessionInCompanies')
	},
	{
		path: '/venture',
		name: 'Venture Capital & Privater Equity',
		component: () => import('../components/Views/Offer/components/EconomicPages/VentureCapital')
	},
	{
		path: '/tech',
		name: 'Nowe technologie i branża IT',
		component: () => import('../components/Views/Offer/components/EconomicPages/IT.vue')
	},
	{
		path: '/branza-kreatywna',
		name: 'Branża kreatywna',
		component: () => import('../components/Views/Offer/components/EconomicPages/CreativeIndustry')
	},
	{
		path: '/ecommerce',
		name: 'E-commerce',
		component: () => import('../components/Views/Offer/components/EconomicPages/E-Commerce')
	},
	{
		path: '/wlasnosc-intelektualna',
		name: 'Własność intelektualna i prace B+R',
		component: () => import('../components/Views/Offer/components/EconomicPages/IntellectualProperty')
	},
	{
		path: '/startupy',
		name: 'Startupy',
		component: () => import('../components/Views/Offer/components/EconomicPages/Startups')
	},
	{
		path: '/dane-osobowe',
		name: 'Dane osobowe',
		component: () => import('../components/Views/Offer/components/EconomicPages/PersonalData')
	},

	//Labor
	{
		path: '/zbiorowe-prawo-pracy',
		name: 'Zbiorowe prawo pracy',
		component: () => import('../components/Views/Offer/components/LaborPages/CollectiveLaborLaw'),
	},
	{
		path: '/pracodawca-zwiazki',
		name: 'Pomoc prawna w relacji Pracodawca – Związki Zawodowe',
		component: () => import('../components/Views/Offer/components/LaborPages/Unions'),
	},
	{
		path: '/procesowe-prawo-pracy',
		name: 'Procesowe prawo pracy / reprezentacja przed organami',
		component: () => import('../components/Views/Offer/components/LaborPages/ProceduralLaborLaw'),
	},
	{
		path: '/prawo-ubezpieczen-spolecznych',
		name: 'Prawo ubezpieczeń społecznych',
		component: () => import('../components/Views/Offer/components/LaborPages/SocialSecurityLaw'),
	},
	{
		path: '/indywidualne-prawo-pracy',
		name: 'Indywidualne prawo pracy',
		component: () => import('../components/Views/Offer/components/LaborPages/IndividualLaborLaw'),
	},
	{
		path: '/szkiolenia',
		name: 'Szkolenia',
		component: () => import('../components/Views/Offer/components/LaborPages/Training'),
	},
	{
		path: '/biezace-doradztwo-podatkowe',
		name: 'Bieżące Doradztwo Podatkowe',
		component: () => import('../components/Views/Offer/components/podatki/doradzctwo.vue'),
	},{
		path: '/ulgi-podatkowe',
		name: 'Ulgi podatkowe',
		component: () => import('../components/Views/Offer/components/podatki/ulgi.vue'),
	},{
		path: '/biura-rachunkowe',
		name: 'Wsparcie Biur Rachunkowych\n',
		component: () => import('../components/Views/Offer/components/podatki/biura.vue'),
	},{
		path: '/kancelarie-prawne',
		name: 'Obsługa Kancelarii Prawnych\n',
		component: () => import('../components/Views/Offer/components/podatki/kancelarie.vue'),
	},
]

const router = createRouter({
	history: createWebHistory(),
	mode: 'hash',
	routes,
})

export default router

<template>
	<section class="offer__box">
		<div class="offer__banner">
			<slot name="banner"></slot>
		</div>

		<div class="offer__box-content">

			<div class="offer__box-nav">
				<slot name="button"></slot>
			</div>

			<div class="offer__box-overview">
				<slot name="small-title"></slot>
				<slot name="title"></slot>
				<slot name="text"></slot>

				<slot name="range"></slot>

				<slot name="list"></slot>
			</div>

		</div>
	</section>
</template>

<script>
export default {
	name: 'Offer',
	data() {
		return {}
	},
}
</script>

<style lang="sass">
@import 'src/assets/sass/variables'

.offer
	&__banner
		max-height: 400px

		img
			width: 100%
			max-height: 400px

	&__box
		height: auto
		padding-bottom: 250px

		&-content
			display: flex
			flex-direction: row

		&-nav
			width: 25%
			height: auto
			display: flex
			justify-content: center
			padding-top: 90px

			a
				text-decoration: underline
				font-family: $font--primary
				font-size: 1.4rem
				font-weight: 400
				display: block
				height: 20px
				letter-spacing: 0

		&-overview
			width: 60%
			min-height: 30vh
			display: flex
			flex-direction: column

			h4
				font:
					size: 1.4rem
					weight: 400
					family: $font--primary
				text-transform: uppercase
				padding-top: 50px

			h2
				padding-top: 10px
				margin-bottom: 30px
				font:
					family: $font--primary
					size: 4.4rem
					weight: 700

			p
				padding: 15px 0
				font:
					family: $font--default
					size: 1.7rem
					weight: 300
				line-height: 30px

			h3
				font:
					family: $font--default
					size: 1.8rem
					weight: 700
				margin-top: 50px
				text-transform: uppercase

	&__list
		width: 70%
		display: flex
		flex-direction: column
		align-items: flex-start

		&-item
			margin: 0 0 0 30px
			padding: 40px 0 0 10px
			list-style: disc
			line-height: 24px
			font:
				size: 1.7rem
				weight: 300

			&::marker
				font-size: 1.2rem

</style>

<style lang="sass">
@media screen and (min-width: 320px) and (max-width: 980px)

	.offer

		&__box
			padding-bottom: 50px

		&__banner
			width: 100vw
			height: 24em

			img
				height: 100%
				width: 100%

		&__box-nav
			position: fixed
			width: 100vw
			height: 5em
			background: white
			border-top: 1px solid rgba(0, 0, 0, .3)
			padding: 0
			bottom: 0
			left: 0
			display: flex
			align-items: center
			justify-content: space-between
			z-index: 5000

			a
				width: 100%
				height: 100%
				display: block
				font-size: 1.4rem
				font-weight: 300
				text-align: center
				line-height: 50px
				text-decoration: none

			i
				position: absolute
				left: 15%

		&__box-overview
			width: 100%
			padding: 0 40px

			h4
				padding-top: 30px
				font-size: 1.2rem

			h2
				font-size: 2rem

			p
				font-size: 1.3rem
				line-height: 23px

			h3
				font-size: 1.3rem
				text-transform: uppercase

		&__list
			width: 100%

		&__list-item
			margin: 0 0 0 10px
			padding: 20px 0 0 10px
			font-size: 1.3rem

			&::marker
				font-size: .9rem

</style>
